import React from "react";
import "../style.css";

function Insructions() {
    return (
        <div id="instructions" className="justify-content-md-center container-fluid">   
        <p className="instructSub">Click on the Image to navigate to live site</p> 
        <p className="instructSub">Click on the banner above the image to navigate to the Github repository</p>     
</div>   
    )
}

export default Insructions;